/* Default style */
/* Do not add margins here, add placement to your specific button */
.button {
  @apply leading-none transition-colors duration-200 rounded border-2 border-transparent;
  @apply font-serif text-lg font-bold whitespace-nowrap;
  @apply min-h-12 px-4 flex items-center justify-center;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-70 disabled:bg-black-25 disabled:border-black-25;
  @apply disabled:text-black-100;
  @apply disabled:hover:bg-black-25;

  &:global(.disabled) {
    @apply cursor-not-allowed;
    @apply opacity-70;
    @apply bg-black-25;
    @apply border-black-25;
    @apply text-black-100;
    @apply hover:bg-black-25;
  }
}

.arrow {
  @apply inline-block w-[16px] mr-1.5;
}

/*  FIXME t ex blurbs */
.size-small {
  @apply px-3 py-2 font-serif text-base font-bold;
}

.size-mini {
  @apply px-3 py-2 font-serif text-sm font-bold;
}

.text-sans {
  @apply font-sans;
}

/* Themes */
.theme-secondary {
  @apply bg-transparent border-black-100 border;
  @apply hover:bg-white/75;
  @apply focus:bg-white/80;
  @apply active:bg-white/80;
  @apply disabled:bg-transparent disabled:hover:bg-transparent disabled:hover:border-black-100/80;
}

.theme-light-lime {
  @apply bg-summer-green-50 border-summer-green-50 text-black-100;
  @apply hover:bg-summer-green-50/50;
  @apply focus:bg-summer-green-75/80 focus:border-summer-green-75;
  @apply active:bg-summer-green-75;
  @apply disabled:hover:border-summer-green-75;
}

.theme-lime,
.theme-campaign-dark-lime {
  @apply bg-summer-green-100 border-summer-green-100 text-black-100 hover:bg-summer-green-50;
  @apply hover:bg-summer-green-50;
  @apply focus:bg-summer-green-50/80;
  @apply active:bg-summer-green-50;
  @apply disabled:hover:border-summer-green-100;
}

.theme-campaign-light-lime {
  @apply bg-campaign-light-lime-100 text-black-100 border-campaign-light-lime-125;
  @apply hover:bg-campaign-light-lime-100/60;
}

.theme-green,
.theme-dark-green,
.theme-campaign-dark-green {
  @apply bg-green-100 border-green-100 text-black-100;
  @apply hover:bg-green-100/50;
  @apply disabled:hover:border-green-100;
}

.theme-light-green,
.theme-campaign-green {
  @apply bg-green-50 border-green-50 text-black-100;
  @apply hover:bg-green-50/75 hover:border-green-100;
  @apply focus:bg-green-75/80 focus:border-green-100;
  @apply active:bg-green-75 active:border-green-100;
}

.theme-cyan,
.theme-campaign-cyan {
  @apply bg-dark-cyan-100 border-dark-cyan-100 text-white;
  @apply hover:bg-dark-cyan-75;
  @apply focus:bg-green-100/80;
  @apply active:bg-dark-cyan-100;
}

.theme-campaign-lime {
  @apply bg-summer-green-50 text-black-100 border-summer-green-50 hover:bg-summer-green-75/50 hover:border-summer-green-100;
}

.theme-text {
  @apply py-0 px-0 !important;
  @apply mx-0 border-none bg-transparent text-black-100 hover:underline;
  @apply active:bg-transparent;
  @apply focus:bg-transparent;
  @apply disabled:bg-transparent disabled:hover:bg-transparent;
  @apply flex justify-start items-center;
}

.theme-yellow,
.theme-campaign-yellow {
  @apply bg-campaign-yellow-100 text-black-100 border-campaign-yellow-110 hover:bg-campaign-yellow-110;
}

.theme-light-cyan {
  @apply bg-dark-cyan-100/80 text-black-100 border-dark-cyan-100 hover:bg-dark-cyan-100;
}

.theme-black,
.theme-campaign-black {
  @apply bg-black-100 border-black-100 text-white hover:bg-black-100/80;
}

.theme-gray {
  @apply bg-black-50 border-black-50 text-white hover:bg-black-50/80;
}

.theme-warning {
  @apply bg-red-110 border text-white hover:bg-red-110/90;
}
