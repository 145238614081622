.heading {
  @apply text-lg font-bold;
}

.logos {
  @apply flex flex-wrap items-center mt-8 mb-6;

  & :global(.logo) {
    @apply relative inline-block mb-5 mr-8;
  }

  & :global(a) {
    @apply max-h-15;
  }

  & :global(img) {
    @apply max-h-15 max-w-[198px];
  }
}
