.sidebar {
  @apply fixed top-0 right-0 w-full h-screen transform translate-x-full z-100 xl:translate-x-0;
  @apply transition-transform ease-in-out delay-75;
  @apply max-w-[380px];

  &:global(.active) {
    @apply translate-x-0;
  }

  & :global(.container) {
    @apply pt-15 pb-7.5 px-7.5 bg-white h-full overflow-auto;

    &:before {
      @apply absolute top-0 left-0 block h-full -z-1 content-[""];
      box-shadow: 0px 0 6px 1px rgba(16, 16, 16, 0.5);
    }
  }

  & :global(.close) {
    @apply absolute w-8 h-8 xl:hidden right-7 top-5 bg-black-100 rounded-full;
    @apply flex justify-center items-center xl:hidden;

    & svg {
      @apply w-3 h-auto fill-white;
    }
  }
}

.aside {
  & :global(.floating-wrapper) {
    @apply fixed w-full bottom-0 z-90 xl:hidden bg-white p-4 text-center;
  }

  & :global(.toggle-button) {
    @apply inline-block w-full;
  }
}

@media screen(xl) {
  .sidebar {
    @apply max-w-[460px];
  }
}
