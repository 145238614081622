.toggleCart {
  @apply relative h-full lg:bg-black-100/5 hover:bg-black-100/10;
  @apply min-w-[48px] xsm:aspect-1 xsm:min-w-[auto];

  & :global(> button) {
    @apply flex items-center justify-center w-full h-full focus:outline-none;
  }

  & :global(.cart) {
    @apply w-4 pointer-events-none fill-current sm:w-5;
  }

  & :global(.close) {
    @apply w-3 fill-current stroke-current sm:w-4;
  }

  & :global(.on) {
    @apply bg-black-100/15;
  }

  & :global(.badge) {
    @apply absolute w-5 h-5 text-xs font-medium leading-5 text-center text-black-100 transform translate-x-0.5 -translate-y-full rounded-full top-1/2 left-1/2 bg-summer-green-100;
  }
}
