.nameCollection {
  & :global(.gravity-form) {
    @apply max-w-full;
  }

  & :global(.align-info-center section.collection-info) {
    @apply justify-center;
  }
}

.collectionInfo {
  & :global(.core-heading) {
    @apply mb-5;

    & * {
      @apply pb-0 !important;
    }
  }

  & :global(.collection-row-start) & {
    @apply row-start-1 lg:row-auto;
  }
}

.progressbarWrapper {
  @apply mb-7.5 bg-red-100;

  & :global(.text-wrapper) {
    @apply flex items-end h-10 mb-3 text-lg font-medium leading-8;
  }

  & :global(.current-count) {
    @apply font-serif text-4.5xl font-bold tracking-wide leading-12;
    & p {
      @apply mr-2;
    }
  }

  &:global(.no-progress-bar) {
    @apply mb-2;
  }

  &:global(.is-floating) {
    @apply mb-4;

    & :global(.text-wrapper) {
      @apply h-auto mb-0;
    }

    & :global(.current-count) {
      @apply font-sans text-lg leading-8 font-medium;
      & p {
        @apply mr-1;
      }
    }
  }

  & :global(.progress-bar) {
    @apply relative w-full h-5 overflow-hidden rounded-full;
    & :global(.filler) {
      @apply absolute h-full rounded-full bg-green-50;
    }

    &:global(.light) {
      @apply bg-white border border-black-100/20;
    }

    &:global(.dark) {
      @apply bg-black-100/10 border border-black-50;
    }

    &:global(.white .filler) {
      @apply bg-white;
    }

    &:global(.ink .filler) {
      @apply bg-black-100;
    }

    &:global(.green .filler) {
      @apply bg-green-50;
    }

    &:global(.dark-green .filler) {
      @apply bg-green-100;
    }

    &:global(.light-yellow .filler) {
      @apply bg-summer-green-25;
    }

    &:global(.yellow .filler) {
      @apply bg-campaign-yellow-100;
    }

    &:global(.light-lime .filler) {
      @apply bg-campaign-light-lime-100;
    }

    &:global(.light-cyan .filler) {
      @apply bg-dark-cyan-75;
    }
  }
}
