.header {
  @apply fixed inset-x-0 inset-y-auto z-100;
  @apply transition-colors duration-100 ease-linear;

  &:hover {
    @apply text-black-100 border-black-100/10 bg-white;
  }

  & :global(.menu-bar) {
    @apply flex items-center h-14 sm:h-20 relative;

    &::after {
      @apply block absolute h-px w-full bg-black-100/10 -bottom-px;
      @apply pointer-events-none content-[""];
    }
  }

  &:global(.has-sidebar) {
    @screen xl {
      width: calc(100% - 460px);

      & header {
        width: calc(100% - 460px);
      }
    }
  }
}

.opaque {
  @apply bg-white text-black-100;
}

.textBlack {
  @apply text-black-100;
}

.logo {
  @apply flex items-center h-icon-sm sm:h-icon md:h-icon-lg ml-3 sm:ml-4 md:ml-8 flex-grow justify-start;
  & :global(.falcon) {
    @apply w-icon-sm h-icon-sm sm:w-icon sm:h-icon md:h-icon-lg md:w-icon-lg mr-2.5;
  }
  & :global(.text) {
    @apply h-5 md:h-6 pr-4;
  }
}

.overlay {
  @apply fixed top-0 left-0 z-40 w-full h-full bg-black-110/25 transition-opacity opacity-0;
  @apply pointer-events-none;
}

.is-active {
  @apply opacity-100;
}

@media print {
  .header {
    display: none;
  }
}
