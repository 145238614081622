.wrapper {
  @apply mb-7.5;

  & :global(.text-wrapper) {
    @apply flex items-end h-10 mb-2 text-lg leading-8;
    &:global(.right) {
      @apply flex justify-end;
    }
    &:global(.center) {
      @apply flex justify-center;
    }
  }

  & :global(.current-count) {
    @apply font-sans text-4.5xl font-bold leading-12 pr-2;
  }

  &:global(.no-progress-bar) {
    @apply mb-2;
  }

  &:global(.is-floating) {
    @apply mb-4;

    & :global(.text-wrapper) {
      @apply h-auto mb-0;
    }

    & :global(.current-count) {
      @apply font-sans text-lg leading-8 font-medium;
      & p {
        @apply mr-1;
      }
    }
  }

  & :global(.progress-bar) {
    @apply relative w-full h-8 md:h-10 overflow-hidden rounded;

    & :global(.filler) {
      @apply absolute h-full rounded bg-green-50;
      @apply text-lg flex items-center justify-end pr-3 font-medium;
    }

    &:global(.light) {
      @apply bg-green-25;
    }

    &:global(.dark) {
      @apply bg-black-100/10;
    }

    &:global(.white) {
      @apply border border-black-100/10;
      @apply bg-black-100/10;
      & :global(.filler) {
        @apply bg-white;
      }
    }

    &:global(.ink) {
      @apply bg-black-100/10;
      & :global(.filler) {
        @apply bg-black-100 text-white font-bold;
      }
    }

    &:global(.green .filler) {
      @apply bg-green-50;
    }

    &:global(.dark-green .filler) {
      @apply bg-green-100 text-white font-bold;
    }

    &:global(.light-yellow) {
      @apply bg-white;
      & :global(.filler) {
        @apply bg-summer-green-25;
      }
    }

    &:global(.yellow) {
      @apply bg-campaign-yellow-100/30;
      & :global(.filler) {
        @apply bg-campaign-yellow-100;
      }
    }

    &:global(.light-lime) {
      @apply bg-white;
      & :global(.filler) {
        @apply bg-campaign-light-lime-100;
      }
    }

    &:global(.light-cyan) {
      @apply bg-dark-cyan-25;
      & :global(.filler) {
        @apply bg-dark-cyan-75 text-white font-bold;
      }
    }
  }
}
