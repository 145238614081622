.bar {
  @apply font-sans text-sm font-medium flex justify-between;
  background: #1d2327;
  color: #f0f0f1;

  & :global(.actions) {
    @apply flex;
  }

  & :global(.logo) {
    @apply hidden md:block m-2;
    fill: rgba(240, 246, 252, 0.6);
  }

  & :global(.action) {
    @apply flex p-2 cursor-pointer;

    & span {
      @apply hidden md:inline-block;
    }

    & :global(.icon) {
      fill: rgba(240, 246, 252, 0.6);
      @apply mr-1 w-8 h-8 md:w-5 md:h-5;
    }

    &:hover {
      background: #2c3338;
      color: rgb(114, 174, 230);

      & :global(.icon) {
        fill: rgba(114, 174, 230, 1);
      }
    }
  }

  & :global(.user) {
    @apply flex justify-between p-2 cursor-pointer relative;

    & span {
      @apply hidden md:inline-block;
    }

    &:global(.is-active),
    &:hover {
      background: #2c3338;
      & span {
        color: rgb(114, 174, 230);
      }
      & :global(.menu) {
        @apply flex;
      }
    }

    & :global(.icon) {
      @apply aspect-1 border border-white/50 w-7 h-7 md:w-5 md:h-5 ml-2;
    }

    & :global(.menu) {
      background: #2c3338;
      @apply absolute hidden p-4 w-[300px] right-0 top-12 md:top-9 z-100;

      & img {
        @apply mr-4;
      }

      & a:hover {
        color: rgb(114, 174, 230);
      }
    }
  }
}
