.siteLogo {
  width: 198px;
  height: 33px;

  @screen sm {
    width: 240px;
    height: 40px;
  }

  @screen lg {
    width: 300px;
    height: 50px;
  }
}
