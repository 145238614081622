.primary {
  @apply h-full aspect-1 text-white;

  & :global(.close) {
    @apply w-3 sm:w-4 fill-current stroke-current;
  }

  & :global(.burger) {
    @apply w-6 sm:w-8 fill-current stroke-current;
  }

  & :global(> .toggle) {
    @apply h-full w-full flex flex-col items-center justify-center text-white;
    @apply transition-colors duration-200 bg-black-100 border-0 rounded-none;
    @apply focus:outline-none hover:bg-black-100;
  }
}

.submenu {
  @apply fixed transition-transform duration-200 transform top-14 sm:top-20 right-0 z-50;
  @apply h-screen w-full bg-black-100 translate-x-full max-w-sm;

  & :global(.primary > li > a) {
    @apply py-4 block w-full border-b border-white/15 hover:text-summer-green-100;
  }

  & :global(.sub-menu) {
    @apply my-4 text-lg border-l-2 border-white;
    & li {
      @apply pl-4 mb-4 hover:text-summer-green-100;
    }
  }

  & :global(.secondary li) {
    @apply hover:text-summer-green-100;
  }
}

.on {
  @apply translate-x-0;
}

.with-sidebar.on {
  @apply xl:-translate-x-[460px];
}
