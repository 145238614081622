.menu {
  @apply hidden lg:inline-block text-right w-full; /* FIXME breakpoint for Featured */
  @apply font-medium text-sm;

  & :global(li) {
    @apply inline-block;

    & a {
      @apply block leading-10 px-3;
    }
  }
}
