.support {
  @apply h-full cursor-pointer;
  & :global(.main-item) {
    @apply text-black-100 flex items-center justify-center relative h-full;
    @apply font-serif font-bold transition-colors duration-200 whitespace-nowrap;
    @apply px-4 md:px-7 focus:outline-none bg-green-100;
  }

  & :global(.caret) {
    @apply block w-[11px] h-auto ml-2 transition-transform duration-200 transform;
  }

  & :global(.rotate) {
    @apply rotate-180;
  }

  &:global(.light-cyan .main-item),
  & :global(.light-cyan) {
    @apply bg-dark-cyan-100;
  }

  &:global(.light-lime .main-item),
  & :global(.light-lime) {
    @apply bg-campaign-light-lime-100;
  }

  &:global(.yellow .main-item),
  & :global(.yellow) {
    @apply bg-campaign-yellow-100;
  }

  &:global(.light-yellow .main-item),
  & :global(.light-yellow) {
    @apply bg-summer-green-20;
  }

  &:global(.green .main-item),
  & :global(.green) {
    @apply bg-green-50;
  }
}

.submenu {
  @apply absolute w-full right-0 h-screen bg-green-100;
  @apply hidden max-w-sm z-10;

  & :global(.primary li) {
    @apply border-b border-black-100/15;
    & a {
      @apply py-4 block;
    }
  }

  & :global(.primary li a) {
    @apply hover:text-white;
  }

  & :global(.secondary li) {
    @apply hover:text-white;
  }
}

.on {
  @apply block;
}
