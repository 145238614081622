.menu {
  @apply w-full hidden lg:flex justify-end h-20;

  & :global(> li) {
    @apply hover:bg-summer-green-100/30 relative;
    &:hover::after {
      @apply bottom-0 left-0 absolute w-full bg-summer-green-100;
      @apply z-110 content-[""] h-[2px];
    }

    & > a {
      @apply box-content flex items-center h-full px-5 font-semibold whitespace-nowrap;
    }
  }

  & :global(.caret) {
    @apply ml-2 w-[11px] h-auto fill-current transform rotate-0 transition-transform duration-200;

    &:global(.open) {
      @apply rotate-180;
    }
  }

  & :global(.sub-menu) {
    @apply text-black-100 absolute w-84 bg-white top-full;
    @apply z-10 pt-6 pb-2 px-8 font-normal;
    & a {
      @apply w-full block mb-4 hover:underline;
    }
  }

  /* Note: we don't mess with the regular summer-green/green */

  &:global(.yellow) {
    & :global(> li:hover) {
      @apply bg-campaign-yellow-100/30;
      &::after {
        @apply bg-campaign-yellow-100;
      }
    }
  }

  &:global(.light-lime) {
    & :global(> li:hover) {
      @apply bg-campaign-light-lime-100/30;
      &::after {
        @apply bg-campaign-light-lime-100;
      }
    }
  }

  &:global(.light-cyan) {
    & :global(> li:hover) {
      @apply bg-dark-cyan-100/30;
      &::after {
        @apply bg-dark-cyan-100;
      }
    }
  }
}
