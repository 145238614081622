.ninety {
  @apply w-35 mx-auto pb-8;
}

.heading {
  @apply block text-xs uppercase font-bold tracking-widest mt-6;
}

.social {
  @apply flex pt-4 pb-10;

  &:global(.dark li a) {
    @apply text-white bg-white/10 hover:bg-white/30;
  }

  & :global(li a) {
    @apply h-10 w-10 flex items-center justify-center rounded-full;
    @apply bg-black-100/15 hover:bg-black-100/30 mr-6;
  }

  & :global(.service) {
    @apply w-4 fill-current;
  }

  & :global(.youtube) {
    @apply w-[22px];
  }

  & :global(.twitter) {
    @apply w-[14px];
  }
}
