.notice {
  @apply bg-black-100/90 text-base text-white;
  @apply box-content px-4 py-3 mx-auto md:px-8;
  @apply relative;

  & :global(.inner-wrapper) {
    @apply max-w-8xl mx-auto relative pointer-events-none;
  }

  & h4 {
    @apply my-1;
  }

  & span {
    @apply uppercase text-xs font-bold;
    &::before {
      @apply inline-block w-2 h-2 bg-red-100 mr-2 rounded;
      @apply content-[""];
    }
  }

  & :global(.dismiss) {
    @apply cursor-pointer w-10 h-10 absolute top-0 -right-2 md:-right-4 p-3 z-[1] pointer-events-auto;
  }

  & svg {
    @apply w-full fill-white relative z-[1];
  }
}
