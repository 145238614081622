.member {
  @apply lg:relative h-full;

  & :global(.person) {
    @apply w-4 h-4 fill-current;
  }

  &:global(.header) {
    & > a,
    & :global(.content) {
      @apply h-full lg:bg-black-100/5 md:relative md:hover:bg-black-100/10;
      @apply flex justify-center items-center;
      @apply min-w-[48px] xsm:aspect-1 xsm:min-w-[auto];
    }

    & :global(.person) {
      @apply sm:w-4.5 sm:h-5;
    }

    & :global(.close) {
      @apply w-3 sm:w-4 fill-current stroke-current mb-[1px] mt-[3px];
    }

    &:global(.on) {
      @apply bg-summer-green-25;

      & :global(.member-menu) {
        @apply block;
      }
    }
  }

  &:global(.top) {
    @apply min-w-25 h-full leading-10;

    &:global(.on) {
      @apply hover:bg-summer-green-25;
    }

    & :global(.content) {
      @apply cursor-pointer px-3;
    }

    & :global(.caret) {
      @apply inline-block w-[10px] h-[6px] ml-2 fill-current stroke-current transition-transform duration-200 transform;
    }

    &:hover {
      & :global(.caret) {
        @apply rotate-180;
      }

      & :global(.member-menu) {
        @apply block;
      }
    }

    & > a {
      @apply px-4;
    }

    & :global(.person) {
      @apply inline-block mr-[8px] -mt-[2px];
    }
  }

  &:global(.footer) {
    @apply bg-black-75 rounded px-6 pt-6 pb-8 text-lg lg:text-base;
    @apply w-full lg:w-60 mb-8;

    & h3 {
      @apply font-sans text-lg mb-2;
    }

    & p {
      @apply pb-6;
    }

    & :global(.btn) {
      @apply px-5;
    }

    & :global(.content) {
      & > span {
        @apply font-[500] text-xl;
      }
    }

    & ul {
      @apply border-b border-black-50 mt-4 mb-4.5 text-lg;
    }

    & li {
      @apply flex justify-between items-center mb-4;
      & a {
        @apply block leading-normal p-0 cursor-pointer;
      }
    }

    & :global(.label) {
      @apply text-xs font-bold uppercase tracking-[1px] block mb-1;
    }

    & span {
      @apply text-lg font-normal;
    }

    & button {
      @apply w-full block mt-6;
    }
  }

  & :global(.member-menu) {
    @apply hidden;
    @apply absolute top-[100%] right-0 z-100;
    @apply w-full sm:w-[384px];
    @apply bg-summer-green-25 text-left p-6;

    & ul {
      @apply border-b border-black-100/10 mb-6;
    }

    & li {
      @apply flex justify-between items-center mb-4;
      & a {
        @apply block leading-normal p-0 font-[600] text-lg;
      }
    }

    & :global(.label) {
      @apply text-xs font-bold uppercase tracking-[1px] block mb-1;
    }

    & :global(.badge) {
      @apply bg-summer-green-100 rounded-full px-2 py-1 font-bold text-sm;
    }

    & span {
      @apply text-lg font-normal;
    }

    & button {
      @apply w-full block mt-6;
    }
  }
}
