.content {
  @apply absolute bottom-auto right-auto bg-white top-1/2 left-1/2 max-w-1xl rounded-lg;
  @apply outline-none max-h-[95%];
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);

  & :global(.content-wrapper) {
    @apply flex flex-col text-center justify-center items-center p-6 md:p-12;

    & h3 {
      @apply mb-4 md:mb-8;
    }

    & :global(.spinner) {
      @apply w-20 h-20 mb-4;
    }
  }
}
