.wrapper {
  /*
  mobile gutter 16px
  midsize gutter 32px
  */
  @apply box-content px-4 mx-auto md:px-8;

  /*
  Keep this identical to class in utilities;
  @apply element-anchor-scroll-position;
  */
  @apply scroll-mt-[90px] sm:scroll-mt-[120px] lg:scroll-mt-[160px];
}

.gap-normal {
  /* 40 => 60 => 80 */
  @apply mb-10 md:mb-15 lg:mb-20;
}

.gap-none {
  @apply mb-0;
}

.gap-small {
  /* 40 */
  @apply mb-10 /* Used on articles, calendar-singles */;
}

.gap-content {
  /* 40 */
  @apply pb-8 /* Used on content blocks only, list, paragraph etc */;
}

.gap-large {
  /* Campaign specific */
  @apply mb-20 md:mb-25 lg:mb-30;
}

.width-bleed {
  @apply w-full px-0;
}

.width-full {
  @apply max-w-8xl;
}

.width-column {
  @apply max-w-1xl;
}

.width-large {
  @apply max-w-4xl;
}

/* Prevent large to intersect with Article sidebars */
.width-large-article {
  @apply max-w-4xl xl:max-w-[55%] 2xl:max-w-4xl;
}

@media print {
  .wrapper {
    @apply px-4;
  }

  .gap-normal,
  .gap-small,
  .gap-content {
    @apply mb-0 pb-4 !important;
  }
}
