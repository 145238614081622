  
  .content {
    @apply absolute bottom-auto right-auto bg-white top-1/2 left-1/2 max-w-1xl;
    @apply outline-none max-h-[95%];
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
  
    & :global(.content-wrapper) {
      @apply flex-grow p-6 md:p-8 sm:p-10;
      fit-content: fit-content;
    }
  
    & :global(h2) {
      @apply pb-3;
    }
  
    & :global(.button) {
      @apply text-lg py-2.5 px-5 font-serif font-bold;
      @apply inline-block mt-6;
    }
  }

  