.wrapper {
  @apply border-b border-white/20 pb-10 lg:pb-0 mb-8;
  @apply lg:flex lg:justify-between;
}

.menu {
  @apply flex flex-wrap flex-grow;
  & > li {
    @apply w-1/2 sm:w-1/3 xl:w-1/4 mb-4;
    & > a {
      @apply text-lg sm:text-xl font-bold pb-4;
    }
  }

  & :global(a) {
    @apply block hover:underline pb-4;
  }

  & :global(.sub-menu) {
    & a {
      @apply sm:text-lg;
    }
  }

  &:global(.tertiary) {
    & a {
      @apply text-base font-normal;
    }
  }
}

.heading {
  @apply font-sans text-lg mb-6;
}

.secondary {
  @apply mt-6 pb-6 md:mt-0 md:pb-0 md:flex;

  & a {
    @apply hover:underline;
  }

  & li {
    @apply pb-4 md:pb-0;

    &:not(:last-child)::after {
      @apply content-["\2219"] px-2;
    }
  }
}
