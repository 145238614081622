.newsletter {
  @apply relative box-content px-4 md:px-8 mx-auto;

  & :global(.inner-wrapper) {
    @apply bg-black-110 text-white flex flex-col lg:flex-row;
    @apply px-5 md:px-8 lg:px-13 pt-8 pb-8 md:pb-10 max-w-8xl mx-auto;
    @apply relative z-10;
  }

  & :global(.info) {
    @apply pr-5;
  }

  & :global(form) {
    @apply lg:pl-4;
  }

  & :global(.info p) {
    @apply mt-3 text-lg;
  }

  &::before {
    @apply absolute left-0 block w-full bg-black-100 top-1/4;
    @apply h-full;
    @apply content-[""] z-0;
  }

  /* Group colors from chosen Template */
  &:global(.lime) {
    @apply bg-summer-green-50;
  }

  &:global(.green) {
    @apply bg-green-50;
  }

  &:global(.white) {
    @apply bg-white;
  }

  &:global(.campaign-green) {
    @apply bg-green-25;
  }

  &:global(.campaign-yellow) {
    @apply bg-campaign-yellow-100;
  }

  &:global(.campaign-cyan) {
    @apply bg-dark-cyan-100;
  }

  &:global(.campaign-light-lime) {
    @apply bg-campaign-light-lime-100;
  }

  &:global(.campaign-black) {
    @apply bg-black-100;
  }

  &:global(.campaign-white) {
    @apply bg-white;
  }

  &:global(.campaign-forest-green) {
    @apply bg-campaign-forest-green-100;
  }
}

.input {
  @apply w-full mt-6 mb-4 px-5 py-3 text-black-100 focus:text-black-100;
  @apply focus:outline-none placeholder-black-50;
  @apply border rounded;
  @apply transition-colors duration-200;
}

.description {
  @apply text-sm;
  & a {
    @apply underline font-medium;
  }
}

.button {
  @apply mt-6;
  @apply text-sm md:text-base text-black-100 font-serif font-bold;
  @apply border-2 border-transparent rounded px-4 py-2 md:py-2.5;
  @apply bg-summer-green-100 hover:border-black-100/20 hover:bg-summer-green-50;
  @apply disabled:cursor-not-allowed disabled:bg-summer-green-50;
}

.spinner {
  @apply opacity-50 inline-block relative top-[10px] left-[10px];
}

@media print {
  .newsletter {
    @apply hidden;
  }
}
