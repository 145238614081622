.footer {
  @apply overflow-hidden;

  & :global(> .inner-wrapper) {
    @apply relative bg-black-100 text-white px-5 sm:px-8;

    & > * {
      @apply max-w-8xl mx-auto;
    }
  }

  & :global(.logos) {
    @apply flex justify-between border-b border-white/20 mb-8 py-8;

    & .logo {
      @apply text-white;
    }

    & :global(.ninety) {
      @apply w-24 md:w-32 lg:w-40;
    }
  }

  & :global(span.heading) {
    @apply text-lg font-bold;
  }

  & :global(.bottom-row) {
    @apply py-8 md:flex justify-between border-t border-white/20;
  }

  & :global(.publisher) {
    @apply text-white/60;
  }

  &:global(.has-sidebar) {
    @screen xl {
      width: calc(100% - 460px);

      & header {
        width: calc(100% - 460px);
      }
    }
  }

  &:global(.campaign-black) {
    @apply bg-black-100;
  }

  &:global(.white),
  &:global(.campaign-white) {
    @apply bg-white;
  }

  &:global(.campaign-light-lime) {
    @apply bg-campaign-light-lime-100;
  }

  &:global(.campaign-cyan) {
    @apply bg-dark-cyan-100;
  }

  &:global(.campaign-green) {
    @apply bg-green-25;
  }

  &:global(.campaign-forest-green) {
    @apply bg-campaign-forest-green-100;
  }

  &:global(.campaign-summer-green-25) {
    @apply bg-summer-green-25;
  }

  &:global(.campaign-yellow) {
    @apply bg-campaign-yellow-100;
  }

  &:global(.summer-green) {
    @apply bg-summer-green-23;
  }
}

.section {
  @apply lg:flex;
  & :global(.partners) {
    @apply flex-grow;
  }

  & :global(.social) {
    @apply min-w-96;
  }
}

.heading {
  @apply text-lg font-bold;
}

.social {
  @apply flex pt-6 pb-8;

  &:global(.dark li a) {
    @apply text-white bg-white/10 hover:bg-white/30;
  }

  & :global(li a) {
    @apply h-13 w-13 flex items-center justify-center rounded-full;
    @apply bg-black-100/15 hover:bg-black-100/30 mr-5;
  }

  & :global(.service) {
    @apply w-5 fill-current;
  }

  & :global(.youtube) {
    @apply w-[26px];
  }

  & :global(.twitter) {
    @apply w-[16px];
  }
}

@media print {
  .footer {
    @apply hidden;
  }
}
